import { create } from "zustand";
import { getLocalStorageData } from "../../util/auth";

const ticketCategoryFilter = (state) => {
  const originalList = state.originalList ? state.originalList : [];
  return originalList.filter((item) => {
    if (state.searchTerm.length > 0) {
      return [item.title].join(" ").toLowerCase().includes(state.searchTerm);
    } else {
      return true;
    }
  });
};

const searchItems = (query, items) => {
  if (!query) return items;

  // Split the query into individual terms (e.g., "Ольга Вознюк" becomes ["Ольга", "Вознюк"])
  const queryTerms = query.toLowerCase().split(" ").filter(Boolean);

  return items.filter((item) => {
    const fieldsToSearch = [item.title];

    return queryTerms.every((term) =>
      fieldsToSearch.some(
        (field) => field && field.toLowerCase().includes(term),
      ),
    );
  });
};

const useTicketCategoryFilterStore = create((set) => ({
  isActive: "any",
  alwaysWithinPlan: "any",
  searchTerm: "",
  originalList: [],
  filteredList: [],
  fullTextSearch: (query) =>
    set((state) => ({
      filteredList: searchItems(query, ticketCategoryFilter(state)),
    })),
  isLoading: false,
  fetch: async () => {
    set({ isLoading: true });
    const { token } = getLocalStorageData();
    const response = await fetch(
      `${process.env.REACT_APP_ADDRESS}/api/ticket-categories`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    );
    const data = await response.json();
    set({
      originalList: data,
      isLoading: false,
    });
  },
  updateFilter: (data) =>
    set(() => ({
      isActive: data.nowActive,
      iAmResponsible: data.iAmResponsible,
      alwaysWithinPlan: data.alwaysWithinPlan,
      originalList: data.originalList,
      isLoading: false,
    })),
  applyFilter: () =>
    set((state) => ({ filteredList: ticketCategoryFilter(state) })),
  resetFilter: () => {
    set((state) => ({
      isActive: "any",
      alwaysWithinPlan: "any",
      searchTerm: "",
    }));
    set((state) => ({
      filteredList: ticketCategoryFilter(state),
    }));
  },
}));

export default useTicketCategoryFilterStore;
