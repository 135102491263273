import { useState, useEffect } from "react";
import { redirect, json, useLoaderData } from "react-router-dom";

import { FaNetworkWired } from "react-icons/fa";

import MikrotikDevicesList from "../../components/Devices/Mikrotik/List";

import ListWrapper from "../../UI/ListWrapper";

import { getLocalStorageData } from "../../util/auth";

const MikrotikDevices = () => {
  const data = useLoaderData();
  const devices = data.devices ? data.devices : [];

  const [devicesList, setDevicesList] = useState(devices);

  const [filteredDevices, setFilteredDevices] = useState(devicesList);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setFilteredDevices(
      devicesList.filter((device) =>
        device.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, devicesList]);

  const title = () => {
    return (
      <>
        <FaNetworkWired /> Устройства Mikrotik
      </>
    );
  };

  return (
    <>
      <ListWrapper
        title={title}
        filteredList={filteredDevices}
        setSearchTerm={setSearchTerm}
      >
        <MikrotikDevicesList
          items={filteredDevices}
          setDevicesList={setDevicesList}
        ></MikrotikDevicesList>
      </ListWrapper>
    </>
  );
};

export default MikrotikDevices;

export async function loader() {
  document.title = "УСТРОЙСТВА MIKROTIK";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/mikrotik-devices`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw response;
  }

  return response;
}
