import {
  Form as RouterForm,
  Link,
  useSearchParams,
  useNavigation,
} from "react-router-dom";
import { useState } from "react";

import AlertMessage from "../../UI/AlertMessageV2";

import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Login = ({ data }) => {
  const [searchParams] = useSearchParams();
  const navigation = useNavigation();

  const isLogin =
    searchParams.get("mode") !== "signup" &&
    searchParams.get("mode") !== "forgot-password";
  const isSubmitting = navigation.state === "submitting";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
  };

  const passwordChangeHandler = (event) => {
    setPassword(event.target.value);
  };

  return (
    <>
      {isLogin && (
        <RouterForm method="post">
          <Row className="justify-content-center mb-4">
            <Col sm={6}>
              <AlertMessage message={data?.message} error={data?.error} />
              <Form.Group className="mb-3">
                <FloatingLabel label="E-Mail" htmlFor="email" className="mb-3">
                  <Form.Control
                    required
                    autoComplete="email"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-Mail"
                    value={email}
                    onChange={emailChangeHandler}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel
                  label="Пароль"
                  htmlFor="password"
                  className="mb-3"
                >
                  <Form.Control
                    required
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Пароль"
                    value={password}
                    onChange={passwordChangeHandler}
                  />
                  {/* <Form.Text muted>
                    <Link to='?mode=forgot-password'>Забыли пароль?</Link>
                  </Form.Text> */}
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="text-end">
                <Link to="?mode=signup" className="me-4">
                  Регистрация
                </Link>
                <Button
                  size="lg"
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Вход..." : "Войти"}
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </RouterForm>
      )}
    </>
  );
};

export default Login;
