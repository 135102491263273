import { useState, useRef, useEffect, useContext } from "react";
import {
  Link,
  useNavigate,
  Outlet,
  useFetcher,
  NavLink,
} from "react-router-dom";

import { isBrowser } from "react-device-detect";

import { AuthedUserContext } from "../../store/authed-user-context";
import useInitialPrefsStore from "../../store/prefs";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { formatShortDate } from "../../util/format-date";

import Transitions from "../../animations/Transition";

import { RiEdit2Line, RiArrowGoBackFill } from "react-icons/ri";
import useOffcanvasStore from "../../store/offcanvas";
import AlertMessage from "../../UI/AlertMessage";
import Select from "../../UI/Select";
import DeleteServicePlan from "./DeleteServicePlan";

import { formatPrice } from "../../util/format-string";
import { getWorkingStatus } from "../../util/get-working-status";
import DeleteItem from "../DeleteItem";
import ImageUpload from "./ImageUpload";

const ViewCompany = ({
  company = {},
  servicePlans = [],
  servicePlansList = [],
}) => {
  const { permissions } = useContext(AuthedUserContext);
  const { modules } = useInitialPrefsStore();

  const [profileImage, setProfileImage] = useState(
    company.profileImagePath
      ? `${process.env.REACT_APP_ADDRESS}/uploads/${company.profileImagePath}`
      : "/companypic-placeholder.png",
  );

  const fetcher = useFetcher();
  const navigate = useNavigate();
  const offcanvas = useOffcanvasStore();

  const { workSchedule, clientsSideResponsibles } = company;

  const [newServicePlan, setNewServicePlan] = useState({});
  const isActiveSinceInputRef = useRef();

  const servicePlanChangeHandler = (selectedItem) => {
    setNewServicePlan(selectedItem);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addServicePlanHandler = async (event) => {
    event.preventDefault();

    fetcher.submit(
      {
        intent: "addServicePlan",
        id: company._id,
        servicePlan: newServicePlan._id,
        isActiveSince: new Date(isActiveSinceInputRef.current.value),
      },
      {
        method: "POST",
        action: `/companies/${company._id}`,
      },
    );
  };

  useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data) {
      if (!fetcher.data.error) {
        handleClose();
      }
    }
  }, [fetcher.state, fetcher.data]);

  const workingStatus = getWorkingStatus(company.workSchedule);

  return (
    <Transitions>
      <Row className="justify-content-md-end mb-3">
        {isBrowser && (
          <Col xs="12" sm="auto">
            <Image
              src={profileImage}
              roundedCircle
              style={{ maxWidth: "15rem" }}
            />
          </Col>
        )}
        <Col>
          <Row className="mb-3">
            <Col sm="5">
              <h3>{company.alias}</h3>
              <div className="lead">{company.fullTitle}</div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm="5">
              <Table>
                <tbody>
                  <tr>
                    <th>Телефон</th>
                    <td>
                      {company.phones.map((phone) => (
                        <h6 key={phone}>
                          <a href={"tel:" + phone}>{phone}</a>
                        </h6>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <th>Адрес</th>
                    <td>
                      <a
                        href={company.linkToMap}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {company.address}
                      </a>
                    </td>
                  </tr>
                  {permissions.canManageCompanies && (
                    <tr>
                      <th>Почтовые домены</th>
                      <td>
                        {company.emailDomains.map((domain) => (
                          <h6 key={domain}>{domain}</h6>
                        ))}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <ImageUpload
            companyId={company._id.toString()}
            setProfileImage={setProfileImage}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="6">
          <h4>
            График работы{" "}
            <small
              className={`ms-2 ${workingStatus.isOpened ? "text-success" : "text-danger"}`}
            >
              {workingStatus.verbose}
            </small>
          </h4>
          {!workSchedule && (
            <AlertMessage variant="light" message="Не указан" />
          )}
          {workSchedule && (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>День недели</th>
                    <th>Начало</th>
                    <th>Окончание</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Понедельник</td>
                    <td>{workSchedule.Monday?.start || "-"}</td>
                    <td>{workSchedule.Monday?.end || "-"}</td>
                    <td>
                      {workSchedule.Monday?.is24hours ? "круглосуточно" : ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>Вторник</td>
                    <td>{workSchedule.Tuesday?.start || "-"}</td>
                    <td>{workSchedule.Tuesday?.end || "-"}</td>
                    <td>
                      {workSchedule.Tuesday?.is24hours ? "круглосуточно" : ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>Среда</td>
                    <td>{workSchedule.Wednesday?.start || "-"}</td>
                    <td>{workSchedule.Wednesday?.end || "-"}</td>
                    <td>
                      {workSchedule.Wednesday?.is24hours ? "круглосуточно" : ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>Четверг</td>
                    <td>{workSchedule.Thursday?.start || "-"}</td>
                    <td>{workSchedule.Thursday?.end || "-"}</td>
                    <td>
                      {workSchedule.Thursday?.is24hours ? "круглосуточно" : ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>Пятница</td>
                    <td>{workSchedule.Friday?.start || "-"}</td>
                    <td>{workSchedule.Friday?.end || "-"}</td>
                    <td>
                      {workSchedule.Friday?.is24hours ? "круглосуточно" : ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>Суббота</td>
                    <td>{workSchedule.Saturday?.start || "-"}</td>
                    <td>{workSchedule.Saturday?.end || "-"}</td>
                    <td>
                      {workSchedule.Saturday?.is24hours ? "круглосуточно" : ""}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>Воскресенье</td>
                    <td>{workSchedule.Sunday?.start || "-"}</td>
                    <td>{workSchedule.Sunday?.end || "-"}</td>
                    <td>
                      {workSchedule.Sunday?.is24hours ? "круглосуточно" : ""}{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </Col>
      </Row>
      {permissions.canManageCompanies && (
        <Row className="mb-3">
          <Col xxl="8">
            <h4>Пользователи</h4>
            {company.users.length > 0 && (
              <Accordion className="mb-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{`Раскрыть список (${company.users.length} записей)`}</Accordion.Header>
                  <Accordion.Body>
                    <Table>
                      <thead>
                        <tr>
                          <th>Имя</th>
                          <th>Email</th>
                          <th>Телефон</th>
                        </tr>
                      </thead>
                      <tbody>
                        {company.users.map((user) => {
                          return (
                            <tr key={user._id}>
                              <td>
                                <Link to={"/users/" + user._id} target="_blank">
                                  {user.fullName}
                                </Link>
                              </td>
                              <td>{user.email}</td>
                              <td>{user.phone}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
            {company.users.length === 0 && (
              <Alert variant="light" className="text-centered mb-3">
                Пользователи не найдены
              </Alert>
            )}
          </Col>
        </Row>
      )}

      {modules.finances.isActive && permissions.canUseFinancesModule && (
        <>
          <Row className="mb-3">
            <Col sm="6">
              <h4>Ответственные лица со стороны клиента</h4>
              {clientsSideResponsibles && (
                <>
                  {company.clientsSideResponsibles.map((resp) => {
                    return (
                      <Badge bg="secondary" key={resp._id} className="mx-1">
                        {`${resp.fullName}`}
                      </Badge>
                    );
                  })}
                </>
              )}
              {!clientsSideResponsibles && (
                <AlertMessage variant="light" message="Не указаны" />
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm="6">
              <h4>Ответственные специалисты</h4>
              {company.responsibles.map((resp) => {
                return (
                  <Badge bg="secondary" key={resp._id} className="mx-1">
                    {`${resp.lastName} ${resp.firstName}`}
                  </Badge>
                );
              })}
            </Col>
          </Row>
          {modules.finances.isActive && permissions.canUseFinancesModule && (
            <Row className="mb-3">
              <Col sm="12">
                <h4>Услуги</h4>
                {servicePlans.length === 0 && (
                  <AlertMessage
                    variant="light"
                    message="Услуги не подключены"
                  />
                )}
                {servicePlans.length > 0 && (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Наименование</th>
                        <th>Действует с</th>
                        <th>Тип тарификации</th>
                        <th>Стоимость</th>
                        <th>Действия</th>
                      </tr>
                    </thead>
                    <tbody>
                      {servicePlans.map((plan) => {
                        return (
                          <tr key={plan._id.toString()}>
                            <td data-cell="Наименование">
                              <NavLink
                                to={`/finances/service-plans/${plan._id.toString()}`}
                              >
                                {plan.title}
                              </NavLink>
                            </td>
                            <td data-cell="Действует с">
                              {formatShortDate(plan.isActiveSince)}
                            </td>
                            <td data-cell="Тип тарификации">
                              {plan.tariffing.type === "hourPackage" &&
                                "Пакеты часов"}
                              {plan.tariffing.type === "hourly" &&
                                "Почасовая оплата"}
                              {plan.tariffing.type === "fixedPrice" &&
                                "Фиксированная оплата"}
                            </td>
                            <td data-cell="Стоимость">
                              {plan.tariffing.type === "hourPackage" && (
                                <>
                                  {plan.tariffing.hourPackage.packages.map(
                                    (hourPackage) => (
                                      <li key={hourPackage.hours}>
                                        {`${hourPackage.hours} ч. * ${formatPrice(hourPackage.pricePerHour)}  = ${formatPrice(hourPackage.pricePerHour * hourPackage.hours)}`}
                                      </li>
                                    ),
                                  )}
                                </>
                              )}
                              {plan.tariffing.type === "hourly" && (
                                <>{`${formatPrice(plan.tariffing.hourly.pricePerHour)} / час`}</>
                              )}
                              {plan.tariffing.type === "fixedPrice" && (
                                <>{`${formatPrice(plan.tariffing.fixedPrice.price)}`}</>
                              )}
                            </td>
                            <td data-cell="Действия">
                              {permissions.canManageServicePlans && (
                                <DeleteServicePlan
                                  servicePlan={plan}
                                  companyId={company._id.toString()}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                {permissions.canManageServicePlans && (
                  <Button
                    variant="primary"
                    size="sm"
                    className="mb-3"
                    onClick={handleShow}
                  >
                    Добавить услугу
                  </Button>
                )}
              </Col>
            </Row>
          )}
        </>
      )}
      <Row className="py-3 border-top justify-content-end gap-2">
        <Col sm="auto">
          <Button
            onClick={() => navigate("/companies")}
            className="w-100"
            variant="secondary"
          >
            <RiArrowGoBackFill /> К списку
          </Button>
        </Col>
        {permissions.canManageCompanies && (
          <>
            <Col sm="auto">
              <Button
                as={Link}
                to={`update`}
                className="w-100"
                onClick={offcanvas.show}
              >
                <RiEdit2Line /> Изменить
              </Button>
            </Col>
            <Col sm="auto">
              <DeleteItem isButton item={company} />
            </Col>
          </>
        )}
      </Row>
      <Offcanvas
        show={offcanvas.isActive}
        onHide={() => {
          navigate(-1);
          offcanvas.setClose();
        }}
        keyboard
        placement="bottom"
        className="h-100"
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Outlet />
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={show} centered onHide={handleClose}>
        <Form onSubmit={addServicePlanHandler}>
          <Modal.Header closeButton>
            <Modal.Title>Новая услуга</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {fetcher.data?.error && fetcher.data && (
              <Alert variant="danger">
                <div>{fetcher.data.error}</div>
                <ul>
                  {fetcher.data.duplicates &&
                    fetcher.data.duplicates.map((duplicate) => (
                      <li key={duplicate._id.toString()}>{duplicate.title}</li>
                    ))}
                </ul>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label htmlFor="servicePlan">Услуга</Form.Label>
              <Select
                id="servicePlan"
                placeholder="Выберите услугу"
                required
                isClearable
                isSearchable
                options={servicePlansList.filter(
                  (plan) =>
                    !company.servicePlans.some((sp) => sp._id === plan._id),
                )}
                getOptionLabel={(option) => `${option.title}`}
                getOptionValue={(option) => option._id}
                onChange={servicePlanChangeHandler}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Действует с</Form.Label>
              <Form.Control type="date" ref={isActiveSinceInputRef} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Закрыть
            </Button>
            <Button
              variant="primary"
              type="submit"
              name="intent"
              value="addServicePlan"
              disabled={fetcher.state !== "idle"}
            >
              Сохранить
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Transitions>
  );
};

export default ViewCompany;
