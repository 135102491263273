import { create } from "zustand";
import { getLocalStorageData } from "../../util/auth";

const ticketTemplateFilter = (state) => {
  const originalList = state.originalList ? state.originalList : [];
  return originalList.filter((item) => {
    if (state.searchTerm.length > 0) {
      return [item.title].join(" ").toLowerCase().includes(state.searchTerm);
    } else {
      return true;
    }
  });
};

const searchItems = (query, items) => {
  if (!query) return items;

  // Split the query into individual terms (e.g., "Ольга Вознюк" becomes ["Ольга", "Вознюк"])
  const queryTerms = query.toLowerCase().split(" ").filter(Boolean);

  return items.filter((item) => {
    const fieldsToSearch = [
      item.title,
      item.description,
      JSON.stringify(item.category),
      JSON.stringify(item.company),
      JSON.stringify(item.customFields),
      JSON.stringify(item.sharedCompanies),
      JSON.stringify(item.sharedUsers),
    ];

    return queryTerms.every((term) =>
      fieldsToSearch.some(
        (field) => field && field.toLowerCase().includes(term),
      ),
    );
  });
};

const useTicketTemplateFilterStore = create((set) => ({
  searchTerm: "",
  originalList: [],
  filteredList: [],
  fullTextSearch: (query) =>
    set((state) => ({
      filteredList: searchItems(query, ticketTemplateFilter(state)),
    })),
  isLoading: false,
  fetch: async () => {
    set({ isLoading: true });
    const { token } = getLocalStorageData();
    const response = await fetch(
      `${process.env.REACT_APP_ADDRESS}/api/ticket-templates`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    );
    const data = await response.json();
    set({
      originalList: data,
      isLoading: false,
    });
  },
  updateFilter: (data) =>
    set(() => ({
      originalList: data.originalList,
      isLoading: false,
    })),
  applyFilter: () =>
    set((state) => ({ filteredList: ticketTemplateFilter(state) })),
  resetFilter: () => {
    set((state) => ({
      searchTerm: "",
    }));
    set((state) => ({
      filteredList: ticketTemplateFilter(state),
    }));
  },
}));

export default useTicketTemplateFilterStore;
