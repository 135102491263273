import { useState } from "react";
import pad from "pad";

import Offcanvas from "react-bootstrap/Offcanvas";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import { HiOutlineMagnifyingGlass } from "react-icons/hi2";

const DetailedViewOffcanvas = ({ worktimeWorks = [], overtimeWorks = [] }) => {
  const [showUnrelatedWorks, setShowUnrelatedWorks] = useState(false);

  const handleCloseUnrelatedWorks = () => setShowUnrelatedWorks(false);
  const handleShowUnrelatedWorks = () => setShowUnrelatedWorks(true);

  const msToHMS = (ms) => {
    // 1- Convert to seconds:
    let seconds = ms / 1000;
    // 2- Extract hours:
    const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;

    const humanized = [
      pad(2, hours.toString(), "0"),
      pad(2, minutes.toString(), "0"),
    ].join(":");

    return humanized;
  };

  return (
    <>
      <Button
        onClick={handleShowUnrelatedWorks}
        variant="primary"
        size="sm"
        className="mx-2 my-1"
      >
        <HiOutlineMagnifyingGlass />
      </Button>
      <Offcanvas
        show={showUnrelatedWorks}
        onHide={handleCloseUnrelatedWorks}
        keyboard
        placement="bottom"
        className="h-100"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            Выполненные работы, не попавшие ни в одну из услуг
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <h3>Выполнены в нерабочее время</h3>
            <Table bordered>
              <thead>
                <tr>
                  <th>Заявки</th>
                  <th>Инициаторы</th>
                  <th>Категории</th>
                  <th>Описание работ</th>
                  <th>Исполнитель</th>
                  <th>Длительность</th>
                </tr>
              </thead>
              <tbody>
                {overtimeWorks.map((work) => (
                  <tr
                    key={work._id}
                    className={
                      parseInt(msToHMS(work.duration), 10) >= 12
                        ? "table-warning"
                        : ""
                    }
                  >
                    <td data-cell="Заявки">
                      {work.ticketsNums.map((num) => (
                        <div key={Math.random()}>
                          <a
                            href={`/tickets/${num}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {num}
                          </a>
                          <br></br>
                        </div>
                      ))}
                    </td>
                    <td data-cell="инициаторы">
                      {work.ticketsApplicants.map((applicant) => (
                        <div key={Math.random()}>
                          {applicant}
                          <br></br>
                        </div>
                      ))}
                    </td>
                    <td data-cell="категории">
                      {work.ticketsCategories.map((category) => (
                        <div key={Math.random()}>
                          {category.title}
                          <br></br>
                        </div>
                      ))}
                    </td>
                    <td data-cell="описание работ">{work.description}</td>
                    <td data-cell="исполнитель">{work.finishedBy}</td>
                    <td data-cell="длительность" className="table-align-right">
                      {msToHMS(
                        new Date(work.finishedAt) - new Date(work.startedAt),
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
          <Container>
            <h3>Выполнены в рабочее время</h3>
            <Table bordered>
              <thead>
                <tr>
                  <th>Заявки</th>
                  <th>Инициаторы</th>
                  <th>Категории</th>
                  <th>Описание работ</th>
                  <th>Исполнитель</th>
                  <th>Длительность</th>
                </tr>
              </thead>
              <tbody>
                {worktimeWorks.map((work) => (
                  <tr
                    key={work._id}
                    className={
                      parseInt(msToHMS(work.duration), 10) >= 12
                        ? "table-warning"
                        : ""
                    }
                  >
                    <td data-cell="Заявки">
                      {work.ticketsNums.map((num) => (
                        <div key={Math.random()}>
                          <a
                            href={`/tickets/${num}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {num}
                          </a>
                          <br></br>
                        </div>
                      ))}
                    </td>
                    <td data-cell="инициаторы">
                      {work.ticketsApplicants.map((applicant) => (
                        <div key={Math.random()}>
                          {applicant}
                          <br></br>
                        </div>
                      ))}
                    </td>
                    <td data-cell="категории">
                      {work.ticketsCategories.map((category) => (
                        <div key={Math.random()}>
                          {category.title}
                          <br></br>
                        </div>
                      ))}
                    </td>
                    <td data-cell="описание работ">{work.description}</td>
                    <td data-cell="исполнитель">{work.finishedBy}</td>
                    <td data-cell="длительность" className="table-align-right">
                      {msToHMS(
                        new Date(work.finishedAt) - new Date(work.startedAt),
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default DetailedViewOffcanvas;
