import { useState, useEffect } from "react";

import useSidebarStore from "../../store/sidebar";
import useTicketFilterStore from "../../store/filters/tickets";

import { BrowserView, MobileView } from "react-device-detect";

import { TbCheckbox } from "react-icons/tb";

import List from "../../components/Ticket/List";

import ListWrapper from "../../UI/ListWrapper";

import TicketFilter from "../../components/Ticket/Filter";

import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import Select from "../../UI/Select";

const Tickets = () => {
  const location = useLocation();
  const { setLeftSidebarContent } = useSidebarStore();

  const filterStore = useTicketFilterStore();

  useEffect(() => {
    filterStore.applyFilter();
  }, [filterStore.originalList]);

  useEffect(() => {
    filterStore.fetchOpened();
  }, [location]);

  useEffect(() => {
    if (filterStore.nowActive === "recently_closed") {
      filterStore.fetchRecentlyClosed();
    }
  }, [filterStore.nowActive]);

  useEffect(() => {
    filterStore.applyFilter();
  }, [filterStore.originalList]);

  useEffect(() => {
    setLeftSidebarContent(
      <BrowserView>
        <TicketFilter items={filterStore.originalList} />
      </BrowserView>,
    );
  }, [setLeftSidebarContent, filterStore.tickets]);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const iAmResponsibleToggleHandler = () => {
    filterStore.updateFilter({
      ...filterStore,
      iAmResponsible: !filterStore.iAmResponsible,
    });
    filterStore.applyFilter();
  };

  const showOffcanvasChangeHandler = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const title = () => {
    return (
      <>
        <TbCheckbox /> Заявки
      </>
    );
  };

  return (
    <>
      <ListWrapper
        title={title}
        filteredList={filterStore.filteredList}
        filterStore={filterStore}
        onUpdatePage={filterStore.fetchOpened}
        secondaryList={filterStore.originalList}
        isLoading={filterStore.isLoading || filterStore.isSorting}
        hideLengthRow
        addRoute="/tickets/add"
      >
        <MobileView>
          <Row className="my-3">
            <Col>
              <Form.Check
                type="switch"
                className="form-control-lg"
                id="i-am-responsible"
                label="Назначены на меня"
                value={filterStore.iAmResponsible}
                checked={filterStore.iAmResponsible}
                onChange={iAmResponsibleToggleHandler}
              />
            </Col>
          </Row>
          <Offcanvas
            show={showOffcanvas}
            onHide={() => {
              setShowOffcanvas(false);
            }}
          >
            <Offcanvas.Header closeButton>Фильтр</Offcanvas.Header>
            <Offcanvas.Body>
              <TicketFilter setShowOffcanvas={setShowOffcanvas} />
            </Offcanvas.Body>
          </Offcanvas>
          <Row className="justify-content-between my-3  fs-5">
            <Col>{`Найдено: ${filterStore.filteredList?.length}`}</Col>
          </Row>
          <Row className="justify-content-between align-items-center my-3 fs-6">
            <Col xs={7}>
              <Select
                placeholder="Сортировка"
                defaultValue={filterStore.sortBy}
                options={filterStore.sortingOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => option.label}
                onChange={(selectedItem) =>
                  filterStore.handleSorting(selectedItem)
                }
              />
            </Col>
            <Col
              className="text-end text-success"
              onClick={showOffcanvasChangeHandler}
            >
              <u>Фильтр</u>
            </Col>
          </Row>
        </MobileView>
        <BrowserView>
          <Row className="justify-content-between my-3 fs-6 align-items-end">
            <Col>{`Найдено: ${filterStore.filteredList.length}`}</Col>
            <Col sm={3}>
              <Select
                placeholder="Сортировка"
                defaultValue={filterStore.sortBy}
                options={filterStore.sortingOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => option.label}
                onChange={(selectedItem) =>
                  filterStore.handleSorting(selectedItem)
                }
              />
            </Col>
          </Row>
        </BrowserView>
        <List items={filterStore.filteredList}></List>
      </ListWrapper>
    </>
  );
};

export default Tickets;

export async function loader() {
  document.title = "F1 HD | Заявки";
  return null;
}
