import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ToggleButton from "react-bootstrap/ToggleButton";

import Schedule from "../../UI/Schedule";
import FormWrapper from "../../UI/FormWrapper";
import Select from "../../UI/Select";
import { useEffect } from "react";

const ServicePlanForm = ({ title }) => {
  const { servicePlan = {}, ticketCategories = [] } = useLoaderData();

  const [newServicePlan, setNewServicePlan] = useState({
    title: servicePlan.title || "",
    companyWorkSchedule: servicePlan.companyWorkSchedule || false,
    ticketCategories: servicePlan.ticketCategories || [],
    tariffingPeriod: servicePlan.tariffing?.period || 0,
    tariffingType: servicePlan.tariffing?.type || "fixedPrice",
    fixedPrice: servicePlan.tariffing?.fixedPrice?.price || 0,
    fixedPricePerHourNonWorking:
      servicePlan.tariffing?.fixedPrice?.pricePerHourNonWorking || 0,
    hourlyPrice: servicePlan.tariffing?.hourly?.pricePerHour || 0,
    hourlyPricePerHourNonWorking:
      servicePlan.tariffing?.hourly?.pricePerHourNonWorking || 0,
    hourPackages: servicePlan.tariffing?.hourPackage?.packages || [
      { hours: 12, pricePerHour: 1, totalPrice: 0 },
    ],
    packagesNonWorkingTimeType:
      servicePlan.tariffing?.hourPackage?.nonWorkingTime?.type ||
      "separatePayment",
    packagesNonWorkingTimePrice:
      servicePlan.tariffing?.hourPackage?.nonWorkingTime?.pricePerHour || 0,
    packagesNonWorkingTimeCoefficient:
      servicePlan.tariffing?.hourPackage?.nonWorkingTime?.coefficient || 1,
  });

  const [hourPackages, setHourPackages] = useState(
    servicePlan.tariffing?.hourPackage?.packages || [
      { hours: 12, pricePerHour: 1, totalPrice: 0 },
    ],
  );

  useEffect(() => {
    for (let hourPackage of hourPackages) {
      hourPackage.totalPrice = hourPackage.hours * hourPackage.pricePerHour;
      setHourPackages([...hourPackages]);
    }
  }, []);

  const handleHourPackageHours = (event) => {
    const updatedHourPackages = [...hourPackages];
    updatedHourPackages[+event.target.name].hours = event.target.value;

    updatedHourPackages[+event.target.name].totalPrice = (
      event.target.value * updatedHourPackages[+event.target.name].pricePerHour
    ).toFixed(2);
    setHourPackages(updatedHourPackages);
  };

  const handleHourPackagePrice = (event) => {
    const updatedHourPackages = [...hourPackages];
    const pricePerHour = event.target.value;
    updatedHourPackages[+event.target.name].pricePerHour = pricePerHour;
    updatedHourPackages[+event.target.name].totalPrice =
      pricePerHour * updatedHourPackages[+event.target.name].hours;
    setHourPackages(updatedHourPackages);
  };

  const handleHourPackageTotalPrice = (event) => {
    const updatedHourPackages = [...hourPackages];
    const totalPrice = event.target.value;
    updatedHourPackages[+event.target.name].totalPrice = totalPrice;
    updatedHourPackages[+event.target.name].pricePerHour = (
      totalPrice / updatedHourPackages[+event.target.name].hours
    ).toFixed(2);
    setHourPackages(updatedHourPackages);
  };

  const removeHourPackage = (event) => {
    const updatedHourPackages = hourPackages.filter(
      (_, i) => i !== +event.target.name,
    );
    setHourPackages(updatedHourPackages);
  };

  const onAddHourPackage = () => {
    setHourPackages([
      ...hourPackages,
      { hours: 0, pricePerHour: 1, totalPrice: 0 },
    ]);
  };

  const strChangeHandler = (event) => {
    setNewServicePlan({
      ...newServicePlan,
      [event.target.name]: event.target.value,
    });
  };

  const categoriesChangeHandler = (selectedItems) => {
    setNewServicePlan({
      ...newServicePlan,
      ticketCategories: selectedItems,
    });
  };

  const companyWorkScheduleChangeHandler = () => {
    setNewServicePlan({
      ...newServicePlan,
      companyWorkSchedule: !newServicePlan.companyWorkSchedule,
    });
  };

  const tariffingTypes = [
    { name: "Фиксированная оплата", value: "fixedPrice" },
    { name: "Почасовая оплата", value: "hourly" },
    { name: "Пакеты часов", value: "hourPackage" },
  ];

  const packagesNonWorkingTimeTypes = [
    { name: "Отдельная оплата", value: "separatePayment" },
    { name: "Коэффициент ко времени работ", value: "coefficient" },
  ];

  return (
    <FormWrapper title={title}>
      <Row>
        <Col xl="6">
          <Form.Group className="mb-3">
            <Form.Label htmlFor="title">
              Наименование
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              required
              autoFocus
              id="title"
              name="title"
              type="text"
              value={newServicePlan.title}
              onChange={strChangeHandler}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <Form.Group className="mb-3">
            <Form.Label htmlFor="ticketCategories">
              Категории заявок
              <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Select
              required={ticketCategories ? true : false}
              id="ticketCategories"
              name="ticketCategories"
              placeholder="Выберите категории заявок"
              closeMenuOnSelect={false}
              isClearable
              isSearchable
              isMulti
              value={newServicePlan.ticketCategories}
              options={ticketCategories}
              getOptionLabel={(option) => `${option.title}`}
              getOptionValue={(option) => option._id}
              onChange={categoriesChangeHandler}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            id="companyWorkSchedule"
            name="companyWorkSchedule"
            label="Время оказания услуги согласно графику работы компании"
            value={newServicePlan.companyWorkSchedule}
            checked={newServicePlan.companyWorkSchedule}
            onChange={companyWorkScheduleChangeHandler}
          />
        </Form.Group>
      </Row>
      {!newServicePlan.companyWorkSchedule && (
        <Row>
          <Col xl="6">
            <Form.Label>График оказания услуги</Form.Label>
            <Schedule existingSchedule={servicePlan?.customProvisionSchedule} />
          </Col>
        </Row>
      )}

      <Row>
        <Form.Group className="mb-3">
          <Col sm="12">
            <Form.Label htmlFor="tariffingType" className="me-3">
              Тип тарификации
            </Form.Label>
          </Col>
          <Col>
            <ButtonGroup>
              {tariffingTypes.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  variant={`outline-primary`}
                  name="tariffingType"
                  value={radio.value}
                  checked={newServicePlan.tariffingType === radio.value}
                  onChange={strChangeHandler}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Col>
        </Form.Group>
      </Row>

      {newServicePlan.tariffingType === "fixedPrice" && (
        <>
          <Row className="mb-3">
            <Col xs="auto">
              <Form.Label htmlFor="fixedPrice">Общая стоимость</Form.Label>
              <Form.Control
                type="number"
                id="fixedPrice"
                name="fixedPrice"
                value={newServicePlan.fixedPrice}
                onChange={strChangeHandler}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs="auto">
              <Form.Label htmlFor="fixedPricePerHourNonWorking">
                Стоимость часа в нерабочее время
              </Form.Label>
              <Form.Control
                type="number"
                id="fixedPricePerHourNonWorking"
                name="fixedPricePerHourNonWorking"
                value={newServicePlan.fixedPricePerHourNonWorking}
                onChange={strChangeHandler}
              />
            </Col>
          </Row>
        </>
      )}
      {newServicePlan.tariffingType === "hourly" && (
        <>
          <Row className="mb-3">
            <Col xs="auto">
              <Form.Label htmlFor="hourlyPrice">
                Стоимость часа в рабочее время
              </Form.Label>
              <Form.Control
                type="number"
                id="hourlyPrice"
                name="hourlyPrice"
                value={newServicePlan.hourlyPrice}
                onChange={strChangeHandler}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs="auto">
              <Form.Label htmlFor="hourlyPricePerHourNonWorking">
                Стоимость часа в нерабочее время
              </Form.Label>
              <Form.Control
                type="number"
                id="hourlyPricePerHourNonWorking"
                name="hourlyPricePerHourNonWorking"
                value={newServicePlan.hourlyPricePerHourNonWorking}
                onChange={strChangeHandler}
              />
            </Col>
          </Row>
        </>
      )}
      {newServicePlan.tariffingType === "hourPackage" && (
        <>
          <Button className="mb-3" onClick={onAddHourPackage}>
            Добавить
          </Button>
          <Row className="mb-3">
            {hourPackages.map((hourPackage) => (
              <Col xs="auto" key={`key-${hourPackages.indexOf(hourPackage)}`}>
                <Card className="mb-3">
                  <Card.Body>
                    <Row className="mb-3">
                      <Col xs="auto">
                        <Form.Label htmlFor="">Количество часов</Form.Label>
                        <Form.Control
                          type="number"
                          id={`hours-${hourPackages.indexOf(hourPackage)}`}
                          name={`${hourPackages.indexOf(hourPackage)}`}
                          value={hourPackage.hours}
                          onChange={handleHourPackageHours}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs="auto">
                        <Form.Label>Стоимость часа</Form.Label>
                        <Form.Control
                          type="number"
                          id={`price-${hourPackages.indexOf(hourPackage)}`}
                          name={`${hourPackages.indexOf(hourPackage)}`}
                          value={hourPackage.pricePerHour}
                          onChange={handleHourPackagePrice}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs="auto">
                        <Form.Label>Итоговая стоимость пакета</Form.Label>
                        <Form.Control
                          type="number"
                          id={`total-price-${hourPackages.indexOf(hourPackage)}`}
                          name={`${hourPackages.indexOf(hourPackage)}`}
                          value={hourPackage.totalPrice}
                          onChange={handleHourPackageTotalPrice}
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col xs="auto">
                        <Button
                          size="sm"
                          variant="link"
                          name={`${hourPackages.indexOf(hourPackage)}`}
                          onClick={removeHourPackage}
                        >
                          Удалить
                        </Button>
                      </Col>
                    </Row>
                    <Form.Control
                      hidden
                      id="hourPackages"
                      name="hourPackages"
                      value={JSON.stringify(hourPackages)}
                      onChange={() => {
                        return;
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Form.Group className="mb-3">
              <Col>
                <Form.Label>Учёт работ вне графика оказания услуги</Form.Label>
              </Col>
              <Col>
                <ButtonGroup>
                  {packagesNonWorkingTimeTypes.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`packagesRadio-${idx}`}
                      type="radio"
                      variant={`outline-primary`}
                      name="packagesNonWorkingTimeType"
                      value={radio.value}
                      checked={
                        newServicePlan.packagesNonWorkingTimeType ===
                        radio.value
                      }
                      onChange={strChangeHandler}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Col>
            </Form.Group>
          </Row>
          {newServicePlan.packagesNonWorkingTimeType === "separatePayment" && (
            <Row className="mb-3">
              <Col xs="auto">
                <Form.Label htmlFor="packagesNonWorkingTimePrice">
                  Стоимость часа в нерабочее время
                </Form.Label>
                <Form.Control
                  type="number"
                  id="packagesNonWorkingTimePrice"
                  name="packagesNonWorkingTimePrice"
                  value={newServicePlan.packagesNonWorkingTimePrice}
                  onChange={strChangeHandler}
                />
              </Col>
            </Row>
          )}
          {newServicePlan.packagesNonWorkingTimeType === "coefficient" && (
            <Row className="mb-3">
              <Col xs="auto">
                <Form.Label htmlFor="packagesNonWorkingTimeCoefficient">
                  Коэффициент ко времени работ
                </Form.Label>
                <Form.Control
                  type="number"
                  id="packagesNonWorkingTimeCoefficient"
                  name="packagesNonWorkingTimeCoefficient"
                  value={newServicePlan.packagesNonWorkingTimeCoefficient}
                  onChange={strChangeHandler}
                />
              </Col>
            </Row>
          )}
        </>
      )}
      <Row className="mb-3">
        <Col xs="auto">
          <Form.Label htmlFor="tariffingPeriod">
            Период тарификации, мин.
          </Form.Label>
          <Form.Control
            type="number"
            id="tariffingPeriod"
            name="tariffingPeriod"
            value={newServicePlan.tariffingPeriod}
            onChange={strChangeHandler}
          />
        </Col>
      </Row>
    </FormWrapper>
  );
};

export default ServicePlanForm;
