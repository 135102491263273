import { MobileView } from "react-device-detect";
import Button from "react-bootstrap/Button";

const FilterContainer = ({
  setShowOffcanvas,
  resetFilterHandler,
  children,
}) => {
  return (
    <>
      {children}
      <div className={`sticky-bottom z-3`}>
        <MobileView>
          <Button
            className="w-100 mb-2"
            onClick={() => {
              setShowOffcanvas(false);
            }}
          >
            Применить
          </Button>
        </MobileView>
        <Button
          className="w-100 mb-2"
          variant="warning"
          onClick={() => {
            resetFilterHandler();
            setShowOffcanvas(false);
          }}
        >
          Сбросить
        </Button>
      </div>
    </>
  );
};

export default FilterContainer;
