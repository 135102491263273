import { useState, useEffect } from "react";

import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertToHTML, convertFromHTML } from "draft-convert";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TicketDescription = (props) => {
  const descToolbar = {
    options: ["inline", "fontSize", "list", "link", "emoji"],
  };

  const [description, setDescription] = useState(
    props.description
      ? () => EditorState.createWithContent(convertFromHTML(props.description))
      : () => EditorState.createEmpty(),
  );

  useEffect(() => {
    setDescription(
      props.description
        ? () =>
            EditorState.createWithContent(convertFromHTML(props.description))
        : () => EditorState.createEmpty(),
    );
  }, [props.selectedTemplate]);

  const descriptionChangeHandler = (state) => {
    setDescription(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(description.getCurrentContent());
    props.changeHandler(currentContentAsHTML);
  };

  return (
    <Editor
      id={props.id}
      name={props.name}
      required={props.required}
      value={props.value}
      placeholder={props.placeholder || ""}
      editorStyle={{ minHeight: "17rem" }}
      toolbarClassName="editor-toolbar"
      toolbar={descToolbar}
      localization={{
        locale: "ru",
      }}
      editorState={description}
      onEditorStateChange={descriptionChangeHandler}
    />
  );
};

export default TicketDescription;
