import { useState } from "react";
import { Form as RouterForm } from "react-router-dom";

import { calculateOvertime, calculateWorkTime } from "../../util/finances";

import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import DetailedViewOffcanvas from "./DetailedViewOffcanvas";

const TableActionBar = ({ plan, data, relatedWorks, unrelatedWorks }) => {
  const schedule = plan.companyWorkSchedule
    ? data.company.workSchedule
    : plan.customProvisionSchedule;

  const [canConfirmReport, setCanConfirmReport] = useState();
  return (
    <>
      {unrelatedWorks.length > 0 && (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              В списке не должно быть выполненных работ, не привязанных ни к
              одной услуге
            </Tooltip>
          }
        >
          <span className="d-inline-block">
            <Button
              size="sm"
              variant="success"
              disabled
              style={{ pointerEvents: "none" }}
            >
              Утвердить
            </Button>
          </span>
        </OverlayTrigger>
      )}
      {unrelatedWorks.length === 0 &&
        relatedWorks.filter((work) => !work.finances?.contractor?.isConfirmed)
          .length > 0 && (
          <RouterForm method="post" className="d-inline-block">
            <input
              name="relatedWorks"
              defaultValue={JSON.stringify(relatedWorks)}
              hidden
            />
            <Button
              size="sm"
              type="submit"
              variant="primary"
              name="intent"
              value="confirmReport"
            >
              Утвердить
            </Button>
          </RouterForm>
        )}
      {relatedWorks.filter((work) => !work.finances?.contractor?.isConfirmed)
        .length === 0 && (
        <div className="d-inline-block">
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                {`Отчёт утверждён пользователем ${relatedWorks[0]?.finances.contractor.confirmedBy.lastName} ${relatedWorks[0]?.finances.contractor.confirmedBy.firstName}`}
              </Tooltip>
            }
          >
            <span className="d-inline-block">
              <Button
                size="sm"
                variant="success"
                className="mx-1"
                disabled
                style={{ pointerEvents: "none" }}
              >
                Отчёт утверждён
              </Button>
            </span>
          </OverlayTrigger>
          <RouterForm method="post" className="d-inline-block">
            <input
              name="relatedWorks"
              defaultValue={JSON.stringify(relatedWorks)}
              hidden
            />

            <Button
              size="sm"
              type="submit"
              variant="primary"
              name="intent"
              value="confirmInvoiceGeneration"
            >
              Выставить счёт
            </Button>
          </RouterForm>
        </div>
      )}
      <DetailedViewOffcanvas
        worktimeWorks={
          calculateWorkTime(schedule, relatedWorks, plan.tariffing.period)
            .worktimeWorks
        }
        overtimeWorks={
          calculateOvertime(schedule, relatedWorks, plan.tariffing.period)
            .overtimeWorks
        }
      />
    </>
  );
};

export default TableActionBar;
