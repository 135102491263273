import { redirect } from "react-router-dom";

import Form from "../../components/ClientDevice/Form";
import { getLocalStorageData } from "../../util/auth";

const AddClientDevicePage = () => {
  return <Form title="Новое устройство" />;
};

export default AddClientDevicePage;

export async function loader() {
  document.title = "Новое устройство";

  return null;
}

export async function action({ request }) {
  const { token } = getLocalStorageData();

  const data = await request.formData();

  const clientDevice = Object.fromEntries(data.entries());

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/inventory/client-devices/add`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(clientDevice),
    },
  );

  if ([409].includes(response.status)) {
    return response;
  }

  if (!response.ok) {
    throw response;
  }

  return await response.json();
}
