import { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";

const CheckIfWithinPlan = ({
  work,
  company,
  ticket,
  startedAt,
  finishedAt,
}) => {
  const [withinPlan, setWithinPlan] = useState(work?.withinPlan || false);
  const [outOfSchedule, setOutOfSchedule] = useState(null);

  const withinPlanHandler = () => {
    setWithinPlan(!withinPlan);
  };

  function calculateOvertime(schedule, startedAtStr, finishedAtStr) {
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const startedAt = new Date(startedAtStr);
    const finishedAt = new Date(finishedAtStr);

    // Если время начала и окончания совпадают, возвращаем 0
    if (startedAt.getTime() === finishedAt.getTime()) {
      return 0;
    }

    let currentDate = new Date(
      startedAt.getFullYear(),
      startedAt.getMonth(),
      startedAt.getDate(),
    );
    const endDate = new Date(
      finishedAt.getFullYear(),
      finishedAt.getMonth(),
      finishedAt.getDate(),
    );
    let totalOvertime = 0;

    while (currentDate <= endDate) {
      const dayName = daysOfWeek[(currentDate.getDay() + 6) % 7];
      const daySchedule = schedule[dayName];

      if (daySchedule && daySchedule.isWorking) {
        const [startHour, startMinute] = daySchedule.start
          .split(":")
          .map(Number);
        const [endHour, endMinute] = daySchedule.end.split(":").map(Number);
        const workStart = new Date(currentDate).setHours(
          startHour,
          startMinute,
          0,
          0,
        );
        const workEnd = new Date(currentDate).setHours(
          endHour,
          endMinute,
          0,
          0,
        );

        const dayStart = new Date(
          Math.max(currentDate.getTime(), startedAt.getTime()),
        );
        const dayEnd = new Date(
          Math.min(
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate(),
              23,
              59,
              59,
              999,
            ),
            finishedAt.getTime(),
          ),
        );

        // Переработка до начала рабочего дня
        if (dayStart < new Date(workStart)) {
          totalOvertime += Math.min(
            new Date(workStart) - dayStart,
            dayEnd - dayStart,
          );
        }

        // Переработка после окончания рабочего дня
        if (dayEnd > new Date(workEnd)) {
          totalOvertime += dayEnd - Math.max(new Date(workEnd), dayStart);
        }
      } else {
        // Если день нерабочий, все время считается переработкой
        const dayStart = new Date(
          Math.max(currentDate.getTime(), startedAt.getTime()),
        );
        const dayEnd = new Date(
          Math.min(
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate(),
              23,
              59,
              59,
              999,
            ),
            finishedAt.getTime(),
          ),
        );
        totalOvertime += dayEnd - dayStart;
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return Math.round(totalOvertime / (1000 * 60)); // Конвертируем миллисекунды в минуты
  }

  function formatOvertime(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} часов ${remainingMinutes} минут`;
  }

  useEffect(() => {
    setOutOfSchedule(
      calculateOvertime(company.workSchedule, startedAt, finishedAt),
    );
  }, [startedAt, finishedAt]);

  return (
    <>
      {outOfSchedule > 0 && (
        <Alert variant="danger">
          <Row className="mb-3">
            <Col>
              <strong>{`Указанное время работ выходит за рамки графика оказания услуг на ${formatOvertime(outOfSchedule)} и будет тарифицироваться отдельно.`}</strong>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label htmlFor="withinPlan">
                  Учесть работы как выполненные в рабочее время
                </Form.Label>
                <Form.Check
                  type="switch"
                  checked={withinPlan}
                  value={withinPlan}
                  onChange={withinPlanHandler}
                  id="withinPlan"
                  name="withinPlan"
                />
              </Form.Group>
            </Col>
          </Row>
        </Alert>
      )}
    </>
  );
};

export default CheckIfWithinPlan;
