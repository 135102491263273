import { useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import { formatDateTime } from "../../util/format-date";
import { msToHMS } from "../../util/time-helpers";

import { AddToCalendar } from "../../components/Work/AddToCalendar";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";

import { AuthedUserContext } from "../../store/authed-user-context";

import useViewTicketStore from "../../store/viewTicket";
import Button from "react-bootstrap/esm/Button";
import { GiConfirmed } from "react-icons/gi";
import { RiEdit2Line } from "react-icons/ri";
import { isBrowser } from "react-device-detect";
import DeleteWork from "./Delete";

function ScheduledWorkItem({ work }) {
  const { isAdmin, _id: userId } = useContext(AuthedUserContext);

  const { ticket } = useViewTicketStore();

  const [isNew, setIsNew] = useState(
    new Date() - new Date(work.createdAt) < 10000 ? true : false,
  );

  const isOverdue = new Date(work.planningToStart) < new Date();

  const bgClassName = isNew
    ? "bg-success bg-opacity-10"
    : isOverdue
      ? "bg-danger bg-opacity-10"
      : "bg-warning bg-opacity-10";

  setTimeout(() => {
    setIsNew(false);
  }, 15000);

  return (
    <>
      <Card className={`shadow-sm mb-3 ${bgClassName}`}>
        <Card.Body>
          <Row className="mb-2">
            <Col>
              <Image
                src={
                  work.executor.profileImagePath
                    ? `${process.env.REACT_APP_ADDRESS}/uploads/${work.executor.profileImagePath}`
                    : "/profilepic-placeholder.jpg"
                }
                style={{ maxHeight: "30px" }}
                className="me-2"
                roundedCircle
              />
              <strong>{`${work?.executor?.lastName} ${work?.executor?.firstName}`}</strong>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              {!work.visitRequired
                ? "Запланированы удалённые работы "
                : "Запланирован выезд "}
              на <strong>{formatDateTime(work.planningToStart)}</strong>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              Предварительная длительность{" "}
              <strong>
                {msToHMS(
                  new Date(work.planningToFinish) -
                    new Date(work.planningToStart),
                )}
              </strong>
            </Col>
          </Row>
          <Row className="justify-content-end align-items-end">
            <Col className="mb-3">
              <AddToCalendar
                ticket={ticket}
                start={work.planningToStart}
                finish={work.planningToFinish}
              />
            </Col>
            <Col xs="auto" className="text-end">
              <Row>
                {(isAdmin ||
                  work.createdBy?._id.toString() === userId.toString()) && (
                  <>
                    <Col xs="auto">
                      <Button
                        as={NavLink}
                        to={`work-scheduled/${work._id.toString()}/update`}
                        className=""
                      >
                        <RiEdit2Line /> Изменить
                      </Button>
                    </Col>
                    {isBrowser && (
                      <Col xs="auto" className="text-end">
                        <DeleteWork work={work} />
                      </Col>
                    )}
                  </>
                )}
                {(isAdmin ||
                  work.executor?._id.toString() === userId.toString()) && (
                  <Col xs="auto">
                    <Button
                      as={NavLink}
                      to={`work/${work._id.toString()}/confirm`}
                    >
                      <GiConfirmed /> Подтвердить
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default ScheduledWorkItem;
