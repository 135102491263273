import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import { AuthedUserContext } from "../../../store/authed-user-context";

const CompanyModal = ({ ticket, company = {} }) => {
  const { isEndUser } = useContext(AuthedUserContext);
  const [showCompanyModal, setShowCompanyModal] = useState(false);

  const companyModalShowHandler = () => {
    setShowCompanyModal(true);
  };

  const companyModalCloseHandler = () => {
    setShowCompanyModal(false);
  };

  return (
    <>
      <Badge bg="primary" onClick={companyModalShowHandler}>
        {company.alias}
      </Badge>
      <Modal centered show={showCompanyModal} onHide={companyModalCloseHandler}>
        <Modal.Header closeButton>
          <Modal.Title>Данные компании по Заявке {ticket.num}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>Краткое наименование</th>
                <td>{company.alias}</td>
              </tr>
              <tr>
                <th>Полное наименование</th>
                <td>{company.fullTitle}</td>
              </tr>
              <tr>
                <th>Телефон</th>
                <td>
                  <a href={`tel:${company.phone}`}>{company.phone}</a>
                </td>
              </tr>
              <tr>
                <th>Адрес</th>
                <td>
                  <a href={company.linkToMap} target="_blank" rel="noreferrer">
                    {company.address}
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
          {!isEndUser && (
            <div className="d-flex flex-row-reverse">
              <Button as={Link} to={`/companies/${company._id}`}>
                Подробнее
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CompanyModal;
