import { useState, useEffect } from "react";
import { redirect, json, useLoaderData, useLocation } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";

import { getLocalStorageData } from "../../util/auth";
import useSidebarStore from "../../store/sidebar";
import useUserFilterStore from "../../store/filters/users";

import { RiAccountBoxLine } from "react-icons/ri";

import List from "../../components/User/List";

import ListWrapper from "../../UI/ListWrapper";

import UserFilter from "../../components/User/Filter";

import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Users = () => {
  const location = useLocation();
  const { setLeftSidebarContent } = useSidebarStore();
  const { users } = useLoaderData();
  const filterStore = useUserFilterStore();

  useEffect(() => {
    filterStore.applyFilter();
  }, [filterStore.originalList]);

  useEffect(() => {
    filterStore.fetch();
  }, [location]);

  useEffect(() => {
    setLeftSidebarContent(
      <BrowserView>
        <UserFilter items={users} />
      </BrowserView>,
    );
  }, [setLeftSidebarContent, users]);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const showOffcanvasChangeHandler = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const title = () => {
    return (
      <>
        <RiAccountBoxLine /> Пользователи
      </>
    );
  };

  return (
    <ListWrapper
      title={title}
      filteredList={filterStore.filteredList}
      filterStore={filterStore}
      onUpdatePage={filterStore.fetch}
      secondaryList={users}
      isLoading={filterStore.isLoading}
      hideLengthRow
      addRoute="/users/add"
    >
      <MobileView>
        <Offcanvas
          show={showOffcanvas}
          onHide={() => {
            setShowOffcanvas(false);
          }}
        >
          <Offcanvas.Header closeButton>Фильтр</Offcanvas.Header>
          <Offcanvas.Body>
            <UserFilter items={users} setShowOffcanvas={setShowOffcanvas} />
          </Offcanvas.Body>
        </Offcanvas>
        <Row className="justify-content-between my-3  fs-5">
          <Col>{`Найдено: ${filterStore.filteredList.length}`}</Col>
          <Col
            className="text-end text-success"
            onClick={showOffcanvasChangeHandler}
          >
            <u>Фильтр</u>
          </Col>
        </Row>
      </MobileView>
      <BrowserView>
        <Row className="justify-content-between my-3  fs-5">
          <Col>{`Найдено: ${filterStore.filteredList.length}`}</Col>
        </Row>
      </BrowserView>
      <List items={filterStore.filteredList}></List>
    </ListWrapper>
  );
};

export default Users;

export async function loader() {
  document.title = "F1 HD | ПОЛЬЗОВАТЕЛИ";
  const { token } = getLocalStorageData();

  if (!token) {
    return redirect("/auth");
  }

  const response = await fetch(`${process.env.REACT_APP_ADDRESS}/api/users`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  if (!response.ok) {
    if (response.status === 401 || response.status === 402) {
      return redirect("/auth");
    }
    throw json(
      { message: response.message },
      {
        status: response.status,
      },
    );
  } else {
    return response;
  }
}
