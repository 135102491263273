import { useLoaderData, redirect } from "react-router-dom";

import { getLocalStorageData } from "../../util/auth";

import Table from "react-bootstrap/Table";

import Row from "react-bootstrap/Row";

import { formatPrice } from "../../util/format-string";
import { RiDraftLine } from "react-icons/ri";
import UnrelatedWorksOffcanvas from "../../components/Finances/UnrelatedWorksOffcanvas";

import TableActionBar from "../../components/Finances/tableActionBar";

import {
  calculateWorkTime,
  calculateOvertime,
  filterUnrelatedWorks,
} from "../../util/finances";

const MonthlySummaryReport = () => {
  const data = useLoaderData();

  const splitDataByMonth = (data) => {
    const monthArrays = {};

    data.forEach((company) => {
      company.works.forEach((work) => {
        if (work.finishedAt) {
          const date = new Date(work.finishedAt);
          const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;

          if (!monthArrays[monthKey]) {
            monthArrays[monthKey] = data.map((comp) => ({
              ...comp,
              works: [],
              tickets: [],
            }));
          }

          const companyIndex = monthArrays[monthKey].findIndex(
            (c) => c.company === company.company,
          );
          if (companyIndex !== -1) {
            monthArrays[monthKey][companyIndex].works.push(work);

            // Add associated tickets
            work.tickets.forEach((ticketId) => {
              const ticket = company.tickets.find((t) => t._id === ticketId);
              if (
                ticket &&
                !monthArrays[monthKey][companyIndex].tickets.some(
                  (t) => t._id === ticketId,
                )
              ) {
                monthArrays[monthKey][companyIndex].tickets.push(ticket);
              }
            });
          }
        }
      });
    });

    // Remove companies with no works
    Object.keys(monthArrays).forEach((monthKey) => {
      monthArrays[monthKey] = monthArrays[monthKey].filter(
        (company) => company.works.length > 0,
      );
    });

    return Object.values(monthArrays);
  };

  const splitData = splitDataByMonth(data);

  const getMonthName = (UtcDate) => {
    return new Date(UtcDate)
      .toLocaleDateString("ru-Ru", { month: "long" })
      .toUpperCase();
  };

  return (
    <>
      <h1 className="display-4">
        <RiDraftLine /> Сводный отчёт по оказанным услугам
      </h1>
      <hr></hr>
      {splitData.map((month) => (
        <Row
          key={getMonthName(month[0].works[0].finishedAt)}
          className="px-4 py-4"
        >
          <h3>{getMonthName(month[0].works[0].finishedAt)}</h3>
          <Table className="ms-1" bordered>
            <thead>
              <tr>
                <th>Услуга</th>
                <th>Тариф</th>
                <th className="text-end">Оплата рамках тарифа</th>
                <th className="text-end">Доп. оплата</th>
                <th className="text-end">Итого</th>
                <th>Действия</th>
              </tr>
            </thead>
            {month.map((data) => {
              let totalPrice = 0;
              let totalAdditionalPrice = 0;

              const unrelatedWorks = filterUnrelatedWorks(
                data.works,
                data.tickets,
                data.servicePlans,
              );
              return (
                <tbody key={data.company._id.toString()}>
                  <tr className="table-light">
                    <td colSpan={6}>
                      <strong>{data.company.fullTitle}</strong>
                    </td>
                  </tr>
                  {data.servicePlans.map((plan) => {
                    let totalPrice = 0;
                    let totalAdditionalPrice = 0;

                    const relatedWorks = data.works.filter((work) =>
                      work.tickets.some((ticketId) =>
                        data.tickets.find(
                          (ticket) =>
                            ticket._id === ticketId &&
                            plan.ticketCategories
                              .map((category) => category._id.toString())
                              .includes(ticket.category._id.toString()),
                        ),
                      ),
                    );

                    const schedule = plan.companyWorkSchedule
                      ? data.company.workSchedule
                      : plan.customProvisionSchedule;

                    const hourPackagePrice = (schedule, relatedWorks, plan) => {
                      const workingTime = calculateWorkTime(
                        schedule,
                        relatedWorks,
                        plan.tariffing.period,
                      ).worktime;

                      const workingTimeHours = workingTime / 60;

                      let hourPackageHours = 0;
                      let hourPackagePrice = 0;

                      for (let hourPackage of plan.tariffing.hourPackage
                        .packages) {
                        if (workingTimeHours <= hourPackage.hours) {
                          hourPackageHours = hourPackage.hours;
                          hourPackagePrice =
                            hourPackageHours * hourPackage.pricePerHour;
                          break;
                        }
                      }

                      if (
                        hourPackageHours === 0 &&
                        plan.tariffing.hourPackage.packages.length > 0
                      ) {
                        const lastPackage =
                          plan.tariffing.hourPackage.packages[
                            plan.tariffing.hourPackage.packages.length - 1
                          ];
                        hourPackageHours =
                          lastPackage.hours +
                          (workingTimeHours - lastPackage.hours);
                        hourPackagePrice =
                          hourPackageHours * lastPackage.pricePerHour;
                      }

                      return hourPackagePrice;
                    };

                    const tariff = plan.tariffing.type;

                    const hourlyPrice =
                      (calculateWorkTime(
                        schedule,
                        relatedWorks,
                        plan.tariffing.period,
                      ).worktime *
                        plan.tariffing.hourly.pricePerHour) /
                      60;

                    const fixedPrice = plan.tariffing.fixedPrice.price;

                    const price =
                      tariff === "hourPackage"
                        ? hourPackagePrice(schedule, relatedWorks, plan)
                        : tariff === "hourly"
                          ? hourlyPrice
                          : fixedPrice;

                    const hourlyAdditionalPrice =
                      (calculateOvertime(
                        schedule,
                        relatedWorks,
                        plan.tariffing.period,
                      ).overtime *
                        plan.tariffing.hourly.pricePerHourNonWorking) /
                      60;

                    const fixedPriceAdditionalPrice =
                      (calculateOvertime(
                        schedule,
                        relatedWorks,
                        plan.tariffing.period,
                      ).overtime *
                        plan.tariffing.fixedPrice.pricePerHourNonWorking) /
                      60;

                    const hourPackageAdditionalPrice =
                      plan.tariffing.hourPackage.nonWorkingTime.type ===
                      "separatePayment"
                        ? (calculateOvertime(
                            schedule,
                            relatedWorks,
                            plan.tariffing.period,
                          ).overtime /
                            60) *
                          plan.tariffing.hourPackage.nonWorkingTime.pricePerHour
                        : 0;

                    const additionalPrice =
                      tariff === "hourly"
                        ? hourlyAdditionalPrice
                        : tariff === "fixedPrice"
                          ? fixedPriceAdditionalPrice
                          : hourPackageAdditionalPrice;

                    totalPrice += price;

                    totalAdditionalPrice += additionalPrice;

                    const sum = price + additionalPrice;
                    return (
                      <tr key={plan._id}>
                        <td>{plan.title}</td>
                        <td>
                          {plan.tariffing.type === "hourPackage" &&
                            "Пакеты часов"}
                          {plan.tariffing.type === "hourly" &&
                            "Почасовая оплата"}
                          {plan.tariffing.type === "fixedPrice" &&
                            "Фиксированная оплата"}
                        </td>
                        <td className="text-end">{formatPrice(price)}</td>
                        <td className="text-end">
                          {formatPrice(additionalPrice)}
                        </td>
                        <td className="text-end">{formatPrice(sum)}</td>
                        <td>
                          <TableActionBar
                            plan={plan}
                            data={data}
                            relatedWorks={relatedWorks}
                            unrelatedWorks={unrelatedWorks}
                          />
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    {unrelatedWorks.length > 0 && (
                      <td
                        colSpan={2}
                        className="py-1 bg-danger bg-warning-subtle"
                      >
                        <UnrelatedWorksOffcanvas
                          unrelatedWorks={unrelatedWorks}
                        />
                      </td>
                    )}
                    {unrelatedWorks.length === 0 && <td colSpan={2}></td>}
                    <td className="text-end">
                      <strong>{formatPrice(totalPrice)}</strong>
                    </td>
                    <td className="text-end">
                      <strong>{formatPrice(totalAdditionalPrice)}</strong>
                    </td>
                    <td className="text-end">
                      <strong>{formatPrice(totalPrice + 0)}</strong>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              );
            })}
          </Table>
        </Row>
      ))}
    </>
  );
};

export default MonthlySummaryReport;

export async function loader() {
  document.title = "ОТЧЁТ ПО РАБОТАМ";

  const { token } = getLocalStorageData();

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/finances/monthly-summary-report`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );

  if (!response.ok) {
    throw response;
  }

  return await response.json();
}

export async function action({ request }) {
  const { token } = getLocalStorageData();

  const data = await request.formData();

  const relatedWorksData = JSON.parse(data.get("relatedWorks"));

  const response = await fetch(
    `${process.env.REACT_APP_ADDRESS}/api/finances/monthly-summary-report/confirm-works`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ relatedWorks: relatedWorksData }),
    },
  );

  if ([400, 409].includes(response.status)) {
    return response;
  }

  if (!response.ok) {
    throw response;
  }

  return redirect(".");
}
